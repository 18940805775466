import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { finalize, map } from 'rxjs';

import { GroupNew } from '../policy-details/policy-details.types';
import { UserStore } from '../user/user.store';
import { GroupFromList, PoliciesByCustomer } from './policies.types';

@Injectable({
	providedIn: 'root',
})
export class PoliciesService {
	readonly #store = inject(UserStore);
	apiService = inject(ApiService);

	isLoading = signal<boolean>(false);

	addPolicy(data: GroupNew) {
		const access = this.#store.selectedGroup();

		return this.apiService.post<string>('/android/groups', data, { params: { access } });
	}

	deletePolicy(id: string) {
		return this.apiService.delete(`/android/groups/${id}`);
	}

	getByCustomer(customerCode: string, customerId?: string) {
		this.isLoading.set(true);

		return this.apiService
			.get<GroupFromList[]>(`/android/groups/getbycustomer/${customerCode}`)
			.pipe(
				finalize(() => {
					this.isLoading.set(false);
				}),
				map((response): PoliciesByCustomer => {
					if (!customerId) {
						return { ownerPolicies: [], allPolicies: response };
					}

					const customCustomer = [customerCode, customerId];
					const ownerPolicies = response
						.filter((policy) => customCustomer.includes(policy?.customUser || ''))
						.map((policy) => ({ ...policy, owner: customerCode }));
					const allPolicies = response.map((policy) => ({
						...policy,
						owner: customCustomer.includes(policy?.customUser || '') ? 'Custom' : '',
					}));

					if (response.length > 0 && !(allPolicies.length || ownerPolicies.length)) {
						return { ownerPolicies: [], allPolicies: response };
					}

					return { ownerPolicies, allPolicies };
				}),
			);
	}

	getPublicPolicies() {
		this.isLoading.set(true);

		return this.apiService.get<GroupFromList[]>('/android/groups/getpublic').pipe(
			finalize(() => {
				this.isLoading.set(false);
			}),
			map((response): PoliciesByCustomer => {
				return { ownerPolicies: [], allPolicies: response };
			}),
		);
	}
}
